import React from 'react';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';

const withErrorBoundary = (WrappedComponent) => {
  const WithErrorBoundary = (props) => (
    <ErrorBoundary global>
      <WrappedComponent {...props} />
    </ErrorBoundary>
  );

  return WithErrorBoundary;
};

export default withErrorBoundary;
