import { schema } from 'normalizr';

export const CLUB_LIST = '/:clientToken/clubs';

// ------------------------------------
// Schemas
// ------------------------------------

const CLUB_SCHEMA = new schema.Entity('clubs', {}, { idAttribute: '@id' });

const clubEntityType = {
  Club: CLUB_SCHEMA,
};

export default clubEntityType;
