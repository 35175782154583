import { schema, denormalize } from 'normalizr';
import saleSchemas from './saleApi';
import fileSchemas from './fileApi';
import clubSchemas from './clubApi';
import communicationSchemas from './communicationApi';
import contactSchema from './contactApi';
import customThemeSchemas from './customThemeApi';
import intlSchemas from './intlApi';

const ENTITY_SCHEMA = new schema.Union(
  {
    ...saleSchemas,
    ...fileSchemas,
    ...clubSchemas,
    ...communicationSchemas,
    ...contactSchema,
    ...customThemeSchemas,
    ...intlSchemas,
  },
  '@type'
);

export default ENTITY_SCHEMA;

/**
 * @typedef {Object.<string, Collection>} Entities
 * @typedef {Object.<string, Entity>} Collection
 * @typedef {Object} Entity
 * @typedef {{id: string, schema: string}} EntityReference
 */

/**
 * Returns the denormalized version of an entity
 *
 * @param {EntityReference} entityRef
 * @param {Entities} entities
 * @param {Object} outputSchema
 * @returns {Entity}
 */
export const denormalizeEntity = (entityRef, entities, outputSchema = ENTITY_SCHEMA) =>
  denormalize(entityRef, outputSchema, entities);

/**
 * Returns the denormalized version of an entity list
 *
 * @param {EntityReference[]} entityRefs
 * @param {Entities} entities
 * @param {Object} outputSchema
 * @returns {Entity[]}
 */
export const denormalizeEntityList = (entityRefs, entities, outputSchema = [ENTITY_SCHEMA]) =>
  denormalize(entityRefs, outputSchema, entities);
