import { createSelector } from '@reduxjs/toolkit';
import { getClientScreen } from './clientScreen';

/**
 * Returns headers needed for API calls
 *
 * @param {Object} state
 */
export const getAuthenticationHeaders = createSelector(
  [getClientScreen],
  (screen) => screen.authenticationHeaders
);
