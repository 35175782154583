import io from 'socket.io-client';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getClientToken, getSocketConfig } from '../selectors/config';

/**
 * @param {string|null} namespace
 * @param {(...args: any[]) => void} [callback]
 */
function useSocketConnection(namespace, callback) {
  const config = useSelector(getSocketConfig);
  const socketUri =
    config.baseUrl + (namespace ?? '').replace(':clientToken', useSelector(getClientToken));
  const socketOpts = useMemo(
    () => ({
      autoConnect: false,
      transports: ['websocket'],
      reconnectionAttempts: config.maxReconnection,
      query: { app: 'resa2-desk-terminal' },
    }),
    [config.maxReconnection]
  );

  const [socket, setSocket] = useState(() => ({ namespace, instance: io(socketUri, socketOpts) }));
  useEffect(() => {
    if (namespace !== socket.namespace) {
      setSocket({ namespace, instance: io(socketUri, socketOpts) });
    }
  }, [namespace, socket.namespace, socketOpts, socketUri]);

  const cbRef = useRef(callback);
  cbRef.current = callback;

  useEffect(() => {
    if (socket.namespace === null) return;

    const fn = (...args) => cbRef.current?.(...args);
    socket.instance.onAny(fn);
    socket.instance.connect();

    // eslint-disable-next-line consistent-return
    return () => {
      cbRef.current?.('peer_update', []);
      socket.instance.close();
      socket.instance.offAny(fn);
    };
  }, [socket.instance, socket.namespace]);

  const broadcast = useCallback(
    /**
     * @param {string} msgType
     * @param  {...unknown} args
     */
    (msgType, ...args) => {
      if (socket.namespace === null) return;
      socket.instance.emit('broadcast', [msgType, ...args]);
    },
    [socket.instance, socket.namespace]
  );

  return broadcast;
}

export default useSocketConnection;
