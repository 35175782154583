import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import GroupeStadline from './xplor.png';

const PoweredBy = ({ positionStyle }) => {
  const intl = useIntl();

  return (
    <Grid item style={positionStyle}>
      <Grid container alignItems="center" justifyContent="center" direction="row" wrap="nowrap">
        <Grid item>
          <Typography variant="caption">
            <FormattedMessage id="powered-by.title" />
          </Typography>
        </Grid>
        <Grid item>
          <img src={GroupeStadline} alt={intl.formatMessage({ id: 'powered-by.stadline' })} />
        </Grid>
      </Grid>
    </Grid>
  );
};

PoweredBy.propTypes = {
  positionStyle: PropTypes.object,
};

PoweredBy.defaultProps = {
  positionStyle: {},
};

export default PoweredBy;
