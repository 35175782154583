import React from 'react';
import moment from 'moment';
// https://material-ui-pickers.firebaseapp.com/installation, https://material-ui-pickers.firebaseapp.com/localization/moment
import 'moment/locale/fr'; // this is the important bit, you have to import the locale your'e trying to use.
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

moment.locale('fr');

export const withMuiPickersUtilsProvider = (WrappedComponent) => (props) => {
  return (
    <LocalizationProvider
      adapterLocale="fr"
      dateAdapter={AdapterMoment}
      dateFormats={{ fullDate: 'DD MMM YYYY' }}
    >
      <WrappedComponent {...props} />
    </LocalizationProvider>
  );
};

export default withMuiPickersUtilsProvider;
