import makeApiClient from '../services/makeApiClient';
import { getApiConfig, getResolvedUri } from '../selectors/config';
import { getAuthenticationHeaders } from '../selectors/authentication';

export const getHTTPRequestConfiguration = (requestconfig) => (_, getState) => {
  const apiConfig = getApiConfig(getState());
  const authenticationHeaders = getAuthenticationHeaders(getState());

  const configuration = {
    ...requestconfig,
    baseURL: apiConfig.baseUrl,
    url: getResolvedUri(getState(), { uri: requestconfig.url }),
    headers: {
      ...requestconfig.headers,
      ...authenticationHeaders,
    },
  };

  return configuration;
};

export const sendHTTPRequest = (requestconfig) => async (dispatch) => {
  const configuration = await dispatch(getHTTPRequestConfiguration(requestconfig));
  // add an entry to the redux log, to ease debugging
  dispatch({ type: '@@HTTP_REQUEST', ...configuration });

  const client = makeApiClient();
  const httpResponse = await client.request(configuration);

  return httpResponse;
};
