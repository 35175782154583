import { schema } from 'normalizr';

export const SALES_LIST = '/:clientToken/sales';
export const ARTICLES_LIST = '/:clientToken/articles';

const SALE_SCHEMA = new schema.Entity('sales', {}, { idAttribute: '@id' });
const ARTICLE_SCHEMA = new schema.Entity('articles', {}, { idAttribute: '@id' });

const entityTypes = {
  Article: ARTICLE_SCHEMA,
  Sale: SALE_SCHEMA,
};

export default entityTypes;
