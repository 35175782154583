import React, { Component } from 'react';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import rootReducer from './reducers';

const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem('state', serializedState);
  } catch (err) {
    // Ignore write errors.
  }
};

const withRedux = (WrappedComponent) => {
  class WithRedux extends Component {
    constructor(...args) {
      super(...args);

      const preloadedState = loadState();
      const store = configureStore({ reducer: rootReducer, preloadedState });

      store.subscribe(() => {
        saveState({
          authentication: store.getState().authentication,
          // messages: store.getState().messages,
        });
      });

      this.store = store;
    }

    render() {
      return (
        <Provider store={this.store}>
          <WrappedComponent {...this.props} />
        </Provider>
      );
    }
  }

  return WithRedux;
};

export default withRedux;
