import { createReducer } from '@reduxjs/toolkit';
import qs from 'qs';

const { club, networkNode } = qs.parse(document.location.search.substring(1));

if (club) sessionStorage.setItem('club', club);
if (networkNode) sessionStorage.setItem('networkNode', networkNode);

const initialState = {
  id: new Date().valueOf(),
  name: 'standby',
  props: {},
  authenticationHeaders: sessionStorage.getItem('networkNode')
    ? {
        'X-User-Network-Node-Id': sessionStorage.getItem('networkNode'),
        'X-User-Club-Id': sessionStorage.getItem('club'),
      }
    : {},
};

// ------------------------------------
// Actions
// ------------------------------------

export const change = (screenName, message) => ({
  type: 'clientScreen/CHANGE',
  screenName,
  message,
});

export const reset = () => ({
  type: 'clientScreen/RESET',
});

// ------------------------------------
// Handlers
// ------------------------------------

const handleChange = (_prevState, { screenName, message }) => ({
  id: new Date().valueOf(),
  name: screenName,
  props: message.payload,
  authenticationHeaders: message.headers,
});

const handleReset = () => initialState;

// ------------------------------------
// Reducer
// ------------------------------------

export default createReducer(initialState, {
  'clientScreen/CHANGE': handleChange,
  'clientScreen/RESET': handleReset,
});
