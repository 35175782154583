import React from 'react';
import { useSelector } from 'react-redux';
import { getDeviceId } from '../../selectors/config';
import useSocketConnection from '../../socket.io-react/useSocketConnection';

const withMessageLogger = (WrappedComponent) => {
  const Component = (props) => {
    const deviceId = useSelector(getDeviceId);

    useSocketConnection(`/:clientToken/devices/${deviceId}`, (_, msg) =>
      // eslint-disable-next-line no-console
      console.log(`Received "${msg.type}" message: `, msg)
    );

    return <WrappedComponent {...props} />;
  };

  return Component;
};

export default withMessageLogger;
