/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/material';
import { mix } from 'polished';

const styles = ({ theme }) => ({
  '#PaylineWidget': {
    position: 'static',
    padding: '0 !important',
    '& .pl-modal-dialog': {
      width: '100%',
      margin: '0 auto',
      padding: '0',
      boxShadow: 'none',
    },
    '& .pl-header': { display: 'none !important' },
    '& .pl-body': {
      background: 'transparent',
      borderRadius: '0',
      paddingTop: '0',
    },
    '& .pl-wallet-layout': {
      background: 'transparent',
      border: '0',
      padding: '0',
    },
    '& .pl-walletrow, .pl-pmContainer': {
      border: '0',
      boxShadow:
        '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
      background: '#f8f9fa',
    },
    '& .pl-wallet-infos-main': { lineHeight: 1.3 },
    '& .pl-defaultLabel-container': {
      right: 'auto',
      left: '65px',
      bottom: '-2px',
      '& .pl-defaultLabel-icon': { display: 'none' },
    },
    '& .pl-pay-btn, .pl-mng-btn-confirm.pl-confirm-yes': {
      fontFamily: theme.typography.fontFamily,
      fontSize: '14px',
      fontWeight: 500,
      textTransform: 'uppercase',
      backgroundColor: theme.palette.primary.main,
      '&:hover, &:active, &:focus': {
        backgroundColor: mix(0.7, theme.palette.primary.main, '#000'),
      },
    },
    '& .pl-mng-btn.pl-mng-open-btn.pl-icon-pencil,.pl-mng-btn-confirm.pl-confirm-no': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
      '& &:hover, &:active, &:focus': {
        backgroundColor: mix(0.8, theme.palette.common.white, theme.palette.primary.main),
      },
    },
    '& .pl-mng-btns-container': {
      color: theme.palette.primary.main,
      borderLeft: '1px solid',
      borderColor: '#dcdcdc',
      borderRadius: '10px',
      left: '70px',
      '& .pl-mng-sub-btn-container': {
        backgroundColor: theme.palette.common.white,
        '& &:hover, &:active, &:focus': {
          backgroundColor: mix(0.8, theme.palette.common.white, theme.palette.primary.main),
        },
      },
    },
    '& .pl-walletrowmng-closed .pl-mng-btns-container': { left: '100%' },
    '& .pl-container-view .pl-has-error .pl-input': {
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.error.main}`,
    },
    '& .pl-message.pl-message-success': { color: theme.palette.primary.main },
    '& .pl-remember-text': { color: theme.palette.common.black },
    '& #pl-container-lightbox-body-title, .pl-message.pl-message-success, .pl-btn.pl-btn-success.pl-ticket-btn, .pl-btn.pl-btn-success.pl-payment-failure-btn, #pl-mgr-finalState-view, .pl-ticket-ticketKeep, .pl-btn.pl-btn-success.pl-token-expired-btn':
      {
        display: 'none',
      },
    '& .pl-image.pl-icon-smiley-sad, .pl-message.pl-message-error': {
      color: theme.palette.error.main,
    },
    '& .pl-ticket-view': {
      margin: '0',
      backgroundColor: '#ebf9fb',
      padding: theme.spacing(),
      boxShadow: theme.shadows[2],
      borderRadius: '4px',
      '& .pl-ticket': {
        '& h3': {
          padding: '0',
          textTransform: 'none',
          fontWeight: theme.typography.fontWeightBold,
          textAlign: 'left',
          margin: `${theme.spacing(0.5)} 0`,
        },
        '& .pl-ticket-content': {
          background: 'none',
          padding: '0',
          fontWeight: theme.typography.fontWeightSemiBold,
          '& .pl-ticket-security': { display: 'none' },
          '& ul.pl-ticket-payment-details': {
            '& li': {
              margin: `${theme.spacing(0.5)} 0`,
              '& *': { fontSize: theme.typography.caption.fontSize },
              '& div': { margin: '0' },
              '& span:first-child': {
                fontWeight: theme.typography.fontWeightSemiBold,
              },
              '& .pl-ticket-ticketAmount, .pl-ticket-ticketAmount span:first-child': {
                fontSize: `${theme.typography.fontSize}px`,
                fontWeight: theme.typography.fontWeightBold,
              },
              '& &:nth-child(3), &:nth-child(5), &:nth-child(7), &:nth-child(8), &:nth-child(10)': {
                display: 'none',
              },
            },
          },
        },
      },
    },
    '& .pl-failure, .pl-onhold-partner, .pl-payment-canceled, .pl-payment-failure, .pl-token-expired':
      {
        padding: '0',
        '& .pl-reason': { color: theme.palette.common.black },
        '& .pl-image': { color: theme.palette.error.main },
      },
  },
});

export const StyledPaylineWidgetWrapper = styled('div')(styles);
