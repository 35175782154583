import PropTypes from 'prop-types';
import { Grid, Typography, useTheme } from '@mui/material';

const MandateInfoRow = ({ items }) => {
  const theme = useTheme();

  return (
    <Grid
      container
      sx={{
        marginTop: 1,
        padding: 1,
        background: theme.palette.grey[100],
        borderRadius: 2,
      }}
    >
      {items.map((item, index) => {
        const isLast = index + 1 === items.length;
        return (
          <Grid
            item
            key={item.label}
            flex={item.flex ?? 1}
            sx={{
              paddingX: 1,
              borderRight: `2px solid ${isLast ? 'transparent' : theme.palette.grey[300]}`, // Apply border to all but last of row. We apply a transparent border to last item to keep everything nicely aligned.
            }}
          >
            <Typography variant="body2" component="h3">
              {item.label}
            </Typography>
            <Typography variant="subtitle2" component="div" paddingTop={0.5}>
              {item.value}
            </Typography>
          </Grid>
        );
      })}
    </Grid>
  );
};

MandateInfoRow.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
      flex: PropTypes.number,
    })
  ).isRequired,
};

export default MandateInfoRow;
