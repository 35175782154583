import { compose, lifecycle, withProps, withHandlers, withStateHandlers } from 'recompose';
import { connect } from 'react-redux';
import { getEntity } from '../../../../selectors/entities';
import { fetchFile, fetchEntity } from '../../../../reducers/entities';
import {
  setAcceptTerms as acceptTermsAction,
  setAcceptMandate as acceptMandateAction,
  setStep as setStepAction,
  changePictureAllowed as changePictureAllowedAction,
  saveFingerprint as saveFingerprintAction,
} from '../../../../reducers/saleContract';
import { getActiveStep, getSaleContract } from '../../../../selectors/saleContract';
import CGVStep from './CGVStep';

const mapStateToProps = (state, { sale, saleId }) => {
  return {
    file: getEntity(state, { collection: 'files', entityId: sale && `${saleId}/contract` }),
    acceptTerms: getSaleContract(state).acceptTerms,
    acceptMandate: getSaleContract(state).acceptMandate,
    activeStep: getActiveStep(state),
  };
};

const actionCreators = {
  setAcceptTerms: acceptTermsAction,
  setAcceptMandate: acceptMandateAction,
  setStep: setStepAction,
  changePictureAllowed: changePictureAllowedAction,
  saveFingerprint: saveFingerprintAction,
};

export default compose(
  connect(),
  connect(mapStateToProps, actionCreators),
  withProps(({ needMandate, acceptMandate, acceptTerms }) => ({
    isButtonActive: acceptTerms && (needMandate ? acceptMandate : true),
  })),
  withStateHandlers(
    {
      zoom: 1,
      isLoading: true,
    },
    {
      setIsLoading: (prevState) => (value) => ({
        ...prevState,
        isLoading: value,
      }),
      setZoom:
        ({ isLoading, ...prevState }) =>
        (value) => ({
          ...prevState,
          isLoading: true,
          zoom: value,
        }),
    }
  ),
  lifecycle({
    async componentDidMount() {
      const { saleId, dispatch, contactId } = this.props;

      dispatch(fetchFile(`${saleId}/contract`));

      if (this.props.contactId) dispatch(fetchEntity(contactId));
    },
    async componentDidUpdate(prevProps) {
      const { sale, dispatch } = this.props;

      if (!prevProps.sale && sale) {
        dispatch(fetchEntity(sale.clubId));
      }
    },
  }),
  withHandlers({
    onChangeAcceptTerms:
      ({ setAcceptTerms }) =>
      (event) => {
        setAcceptTerms(event.target.checked);
      },
    onChangeAcceptMandate:
      ({ setAcceptMandate }) =>
      (event) => {
        setAcceptMandate(event.target.checked);
      },
    nextStep:
      ({ setStep, activeStep }) =>
      () => {
        setStep(activeStep + 1);
      },
  })
)(CGVStep);
