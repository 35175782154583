import { matchPath } from 'react-router';

/**
 * Extracts base url infos
 *
 * @param {string} location
 * @param {string} pattern
 * @returns {{ baseUrl: string, basename?: string, clientToken?: string }}
 */
const parseLocation = (_location, pattern) => {
  const location = _location.split('?')[0];

  const locationMatch = matchPath(location, { path: pattern }) || {
    url: location,
    params: {},
  };

  const baseUrl = locationMatch.url.endsWith('/')
    ? locationMatch.url.slice(0, -1)
    : locationMatch.url;

  const basenameMatch = matchPath(baseUrl, {
    path: ':scheme://:domain/*',
  });

  return {
    baseUrl,
    basename: basenameMatch ? `/${basenameMatch.params[0]}` : undefined,
    clientToken: locationMatch.params.clientToken,
  };
};

export default parseLocation;
