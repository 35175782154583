import React, { Fragment, useCallback, useEffect, useState } from 'react';
import PDF from 'react-pdf-js-infinite';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Button,
  useTheme,
} from '@mui/material';
import { Remove, Add } from '@mui/icons-material';

const buttonStyle = {
  margin: '0 10px',
  fontSize: '36px',
};

const ZoomButtons = ({ onZoom, zoom, disabled }) => (
  <div style={{ position: 'absolute', right: '20px', bottom: '20px', zIndex: 2 }}>
    <Button
      disabled={disabled || zoom <= 0.5}
      style={buttonStyle}
      onClick={() => onZoom(zoom - 0.5)}
      variant="fab"
    >
      <Remove />
    </Button>
    <Button
      disabled={disabled || zoom >= 3}
      style={buttonStyle}
      onClick={() => onZoom(zoom + 0.5)}
      variant="fab"
    >
      <Add />
    </Button>
  </div>
);

ZoomButtons.propTypes = {
  zoom: PropTypes.number.isRequired,
  onZoom: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

const CGVStep = ({
  file,
  contact,
  acceptTerms,
  onChangeAcceptTerms,
  acceptMandate,
  onChangeAcceptMandate,
  nextStep,
  needMandate,
  isButtonActive,
  club,
  zoom,
  setZoom,
  setIsLoading,
  isLoading,
  changePictureAllowed,
  saveFingerprint,
}) => {
  const { spacing } = useTheme();
  const intl = useIntl();
  const [pictureAllowed, setPictureAllowed] = useState(null);
  const [fingerprintAllowed, setFingerprintAllowed] = useState(contact?.fingerprintAllowed);
  useEffect(() => {
    if (contact && contact.pictureAllowed !== undefined) setPictureAllowed(contact.pictureAllowed);
  }, [contact, setPictureAllowed]);

  useEffect(() => {
    if (contact && contact.fingerprintAllowed !== undefined)
      setFingerprintAllowed(contact.fingerprintAllowed);
  }, [contact, setFingerprintAllowed]);

  const handlePictureAllowedChange = useCallback(async (value) => {
    try {
      await changePictureAllowed(value);
      setPictureAllowed(value);
    } catch {
      setPictureAllowed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFingerprintAllowedChange = useCallback(
    async (value) => {
      try {
        await saveFingerprint(value);
        setFingerprintAllowed(value);
      } catch {
        saveFingerprint();
      }
    },
    [saveFingerprint]
  );

  const isPicturePermissionRequired = club && club.picturePermissionRequired;
  const isFingerprintRequired = club?.fingerprintRequired;
  const isDisabled =
    (isPicturePermissionRequired && pictureAllowed === null) ||
    pictureAllowed === undefined ||
    !isButtonActive;

  return (
    <Grid container direction="column" style={{ height: '100%', gridRowGap: spacing(1) }}>
      <Grid item>
        <Typography style={{ marginTop: 0 }} variant="h5" align="center">
          <FormattedMessage id="sale-contract.cgv.title" />
        </Typography>
      </Grid>
      <Grid
        container
        justifyContent="center"
        style={{
          flex: 1,
          borderBottom: '1px solid #f7f6f6',
          borderTop: '1px solid #f7f6f6',
          position: 'relative',
          backgroundColor: '#fff',
        }}
      >
        {file && (
          <Fragment>
            <ZoomButtons zoom={zoom} disabled={isLoading} onZoom={setZoom} />
            <div style={{ overflow: 'auto', height: '100%', width: '100%', position: 'absolute' }}>
              <PDF
                file={file}
                key={zoom}
                scale={zoom}
                onDocumentComplete={() => setIsLoading(false)}
              />
            </div>
          </Fragment>
        )}
      </Grid>
      <Grid item>
        <FormGroup row>
          <FormControlLabel
            control={<Checkbox color="primary" />}
            checked={acceptTerms}
            onChange={onChangeAcceptTerms}
            label={intl.formatMessage({ id: 'sale-contract.cgv.accept-terms' })}
          />
        </FormGroup>

        {needMandate && (
          <FormGroup row>
            <FormControlLabel
              control={<Checkbox color="primary" />}
              checked={acceptMandate}
              onChange={onChangeAcceptMandate}
              label={intl.formatMessage(
                {
                  id: 'sale-contract.cgv.accept-mandate',
                },
                { organization: club ? club.name : '' }
              )}
            />
          </FormGroup>
        )}
        {isPicturePermissionRequired && (
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  value="validated"
                  color="primary"
                  checked={pictureAllowed}
                  onChange={(event) => handlePictureAllowedChange(event.target.checked)}
                  name="validated"
                />
              }
              label={intl.formatMessage({ id: 'sale-contract.pictureAllowed.label' })}
            />
          </FormGroup>
        )}
        {isFingerprintRequired && (
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  value="validated"
                  color="primary"
                  checked={fingerprintAllowed}
                  onChange={(event) => handleFingerprintAllowedChange(event.target.checked)}
                  name="fingerprintAllowed"
                />
              }
              label={intl.formatMessage({ id: 'sale-contract.fingerprint.label' })}
            />
          </FormGroup>
        )}
      </Grid>
      <Grid
        container
        wrap="wrap"
        justifyContent="flex-end"
        alignItems="center"
        style={{ gridGap: spacing(3), marginTop: spacing(1), paddingRight: spacing(1) }}
      >
        <Grid item>
          <Typography variant="caption">
            <FormattedMessage id="sale-contract.mandatory" />
          </Typography>
        </Grid>
        <Grid item>
          <Button color="primary" disabled={isDisabled} onClick={nextStep} variant="contained">
            <FormattedMessage id="global.continue" />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

CGVStep.propTypes = {
  file: PropTypes.string,
  acceptTerms: PropTypes.bool.isRequired,
  onChangeAcceptTerms: PropTypes.func.isRequired,
  acceptMandate: PropTypes.bool.isRequired,
  onChangeAcceptMandate: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  needMandate: PropTypes.bool.isRequired,
  isButtonActive: PropTypes.bool.isRequired,
  club: PropTypes.shape({
    name: PropTypes.string,
    picturePermissionRequired: PropTypes.bool,
    fingerprintRequired: PropTypes.bool.isRequired,
  }),
  contact: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    pictureAllowed: PropTypes.bool,
    fingerprintAllowed: PropTypes.bool,
  }),
  zoom: PropTypes.number.isRequired,
  setZoom: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  changePictureAllowed: PropTypes.func.isRequired,
  saveFingerprint: PropTypes.func.isRequired,
};

export default CGVStep;
