import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import LogoLarge from '../../../../components/LogoLarge';
import PoweredBy from './PoweredBy';
import SaleContractNav from '../SaleContractNav';

const SaleContractLayout = ({ children }) => (
  <Grid container direction="column" style={{ height: '100vh', paddingTop: 20, paddingBottom: 10 }}>
    <Grid
      container
      justifyContent="center"
      style={{ backgroundColor: '#fff', borderBottom: '1px solid #f7f6f6' }}
    >
      <SaleContractNav />
    </Grid>
    <Grid container style={{ flex: 1 }}>
      <Grid item xs={3} style={{ background: '#fff' }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          style={{ height: '100%', position: 'relative' }}
          alignItems="center"
        >
          <Grid container alignItems="center" justifyContent="center" style={{ flex: 1 }}>
            <LogoLarge dimension={180} />
          </Grid>
          <PoweredBy positionStyle={{ marginBottom: '14px' }} />
        </Grid>
      </Grid>

      <Grid item container xs={9} style={{ height: '100%', width: '100%', padding: '20px' }}>
        {children}
      </Grid>
    </Grid>
  </Grid>
);

SaleContractLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SaleContractLayout;
