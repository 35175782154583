import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import MandateInfoRow from './MandateInfoRow';

const MandateInfo = ({ mandate }) => {
  const intl = useIntl();

  return (
    <>
      <Grid item>
        <Typography variant="h6">
          <FormattedMessage id="mandate.infos.title" />
        </Typography>
      </Grid>
      <Grid item>
        <MandateInfoRow
          items={[
            {
              label: intl.formatMessage({ id: 'mandate.infos.bank' }),
              value: mandate.bank,
              flex: 2,
            },
            {
              label: intl.formatMessage({
                id: 'mandate.infos.ics',
              }),
              value: mandate.bankClub?.ics,
            },
          ]}
        />
        <MandateInfoRow
          items={[
            {
              label: intl.formatMessage({ id: 'mandate.infos.holder' }),
              value: mandate.holder,
            },
            {
              label: intl.formatMessage({
                id: 'mandate.infos.iban',
              }),
              value: mandate.iban,
            },
            {
              label: intl.formatMessage({
                id: 'mandate.infos.bic',
              }),
              value: mandate.bic,
            },
          ]}
        />
        <MandateInfoRow
          items={[
            {
              label: intl.formatMessage({ id: 'mandate.infos.umr' }),
              value: mandate.umr,
            },
          ]}
        />
      </Grid>
    </>
  );
};

MandateInfo.propTypes = {
  mandate: PropTypes.shape({
    iban: PropTypes.string.isRequired,
    bank: PropTypes.string,
    bankClub: PropTypes.shape({
      ics: PropTypes.string,
      sun: PropTypes.string,
    }),
    holder: PropTypes.string,
    bic: PropTypes.string,
    umr: PropTypes.string,
  }).isRequired,
};

export default MandateInfo;
