import { schema } from 'normalizr';

export const OPTIN_LIST = '/:clientToken/optins';

// ------------------------------------
// Schemas
// ------------------------------------

const OPTIN_SCHEMA = new schema.Entity('optins', {}, { idAttribute: '@id' });

const optinEntityType = {
  Optin: OPTIN_SCHEMA,
};

export default optinEntityType;
