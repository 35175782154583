import { UseInfiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import useApiClient from 'src/services/useApiClient';
import qs from 'qs';

function useFetch<T>(
  config: AxiosRequestConfig,
  options?: Partial<UseInfiniteQueryOptions<any, any, any, any, any>>
) {
  const axios = useApiClient();
  const { url: requestUrl, params: requestParams, ...requestConfig } = config;
  const initialUrl = requestUrl + qs.stringify(requestParams, { addQueryPrefix: true });

  return useInfiniteQuery({
    ...(options as any),
    queryKey: [config],
    queryFn: ({ pageParam: url = initialUrl }) => axios.request<T>({ ...requestConfig, url }),
    select(data): T {
      const initialData = data.pages[0].data;
      if (initialData['hydra:member'] === undefined) {
        return initialData;
      }

      return data.pages.reduce((acc, curr, idx) => {
        if (idx === 0) return acc;

        const currData = curr.data;
        return {
          ...acc,
          'hydra:member': [...acc['hydra:member'], ...currData['hydra:member']],
          'hydra:view': currData['hydra:view'],
        } as T;
      }, initialData);
    },
    getNextPageParam: (lastPage: AxiosResponse<T>) => lastPage.data['hydra:view']?.['hydra:next'],
  });
}

export default useFetch;
