import { createSelector } from '@reduxjs/toolkit';
import { getEntities } from './entities';
import { getClientScreenProps } from './clientScreen';

export const getSaleContract = (state) => state.saleContract;

export const getActiveStep = createSelector(
  [getSaleContract],
  (saleContract) => saleContract.activeStep
);

export const getSaleId = createSelector(
  [getClientScreenProps],
  (clientScreenProps = {}) => clientScreenProps.saleId
);

export const getSale = createSelector(
  [getSaleId, getEntities],
  (saleId, entities) => entities.sales?.[saleId]
);

export const getClubId = createSelector([getSale], (sale) => sale && sale.clubId);

export const getClub = createSelector(
  [getClubId, getEntities],
  (clubId, entities) => entities.clubs?.[clubId]
);

export const getSaleContact = createSelector([getSale], (sale) => sale && sale.contactId);

export const getContactId = createSelector(
  [getClientScreenProps],
  (clientScreenProps = {}) => clientScreenProps.contactId
);

export const getArticleIds = createSelector(
  [getSaleContract],
  (saleContract) => saleContract.articleIds
);

export const getSteps = createSelector([getSaleContract, getClub], (saleContract, club) => {
  if (club?.contactAutomaticOptin) {
    return saleContract.steps.filter((step) => step !== 'sale-contract.steps-choose-communication');
  }
  return saleContract.steps;
});
