import { useEffect, useCallback, useRef, useReducer } from 'react';
import useFieldChange from './useFieldChange';

function is(x: unknown, y: unknown) {
  if (x === y) {
    return x !== 0 || y !== 0 || 1 / x === 1 / y;
  }
  // eslint-disable-next-line no-self-compare
  return x !== x && y !== y;
}

function shallowEqual(objA: any, objB: any) {
  if (is(objA, objB)) return true;

  if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) return false;

  for (let i = 0; i < keysA.length; i += 1) {
    if (
      !Object.prototype.hasOwnProperty.call(objB, keysA[i]) ||
      !is(objA[keysA[i]], objB[keysA[i]])
    ) {
      return false;
    }
  }

  return true;
}

function useDerivedState<FormValue = any>(
  transform: (prevFormValue?: FormValue) => FormValue,
  deps?: ReadonlyArray<any>,
  reverseTransform?: (nextFormValue: FormValue) => any
) {
  // Internal state is based on the transform result
  // const [formValue, setFormValue] = useState(transform);
  const formValue = useRef<FormValue>();
  const prevDeps = useRef<typeof deps>();

  if (!Array.isArray(deps) || !shallowEqual(prevDeps.current, deps)) {
    formValue.current = transform(formValue.current);
  }
  prevDeps.current = deps;

  const [, rerender] = useReducer((val) => val + 1, 0);
  const { current: setFormValue } = useRef((nextFormValue?: FormValue) => {
    formValue.current = nextFormValue;
    rerender();
  });

  // Update formValue when source value change (like useMemo)
  useEffect(() => {
    if (prevDeps.current !== deps) rerender();
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps

  // Wrap setFormValue to allow a callback function: reverseTransform(value)
  const onFormChange = useCallback(
    async (updater: unknown) => {
      const prevFormValue = formValue.current;
      const nextFormValue = typeof updater === 'function' ? updater(prevFormValue) : updater;

      setFormValue(nextFormValue);

      // call reverseTransform if provided
      if (reverseTransform && nextFormValue !== prevFormValue) {
        try {
          await reverseTransform(nextFormValue);
        } catch (error) {
          setFormValue(prevFormValue);
        }
      }
    },
    [reverseTransform, setFormValue]
  );

  // Helper function to update a field from formValue
  const onFieldChange = useFieldChange(formValue.current as FormValue, onFormChange);

  return [formValue.current as FormValue, onFormChange, onFieldChange] as const;
}

export default useDerivedState;
