import { schema } from 'normalizr';

// ------------------------------------
// Schemas
// ------------------------------------

const CONTACT_SCHEMA = new schema.Entity('contacts', {}, { idAttribute: '@id' });

const contactEntityType = {
  'http://schema.org/Person': CONTACT_SCHEMA,
};

export default contactEntityType;
