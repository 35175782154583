import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Sketchfield from '@stadline/mui-sketch-field';
import { Button, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { getAuthenticationHeaders } from '../../../selectors/authentication';
import { getDeviceId } from '../../../selectors/config';
import useSocketConnection from '../../../socket.io-react/useSocketConnection';

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const SignatureStep = () => {
  const intl = useIntl();

  const deviceId = useSelector((state) => getDeviceId(state));
  const authenticationHeaders = useSelector((state) => getAuthenticationHeaders(state));

  const handleMessage = (_, message) => {
    switch (message.type) {
      case 'cancellation_certificate_signature_reset':
        setValue(null);
        setIsWaiting(false);
        break;
      default:
      // do nothing
    }
  };

  const broadcast = useSocketConnection(`/:clientToken/devices/${deviceId}`, handleMessage);

  const [isWaiting, setIsWaiting] = useState(false);
  const [value, setValue] = useState(null);

  const onConfirm = async () => {
    setIsWaiting(true);
    broadcast('message', {
      type: 'cancellation_certificate_signed',
      payload: value,
      headers: authenticationHeaders,
    });
    await delay(3000);
    setIsWaiting(false);
  };

  return (
    <Grid container spacing={1} direction="column" style={{ height: '100%' }}>
      <Grid item>
        <Typography variant="h5" align="center" style={{ marginTop: 0 }}>
          <FormattedMessage id="cancellation-certificate.signature.title" />
        </Typography>
      </Grid>
      <Grid item style={{ flex: 1 }}>
        <Sketchfield
          value={value}
          placeholder={intl.formatMessage({ id: 'mandate.signature.sketchfield.placeholder' })}
          width="100%"
          height="70%"
          onChange={(newValue) => setValue(newValue)}
        />
      </Grid>
      <Grid container wrap="wrap" spacing={1} justifyContent="flex-end">
        {value && (
          <Grid item>
            <Button
              color="primary"
              onClick={() => setValue(null)}
              disabled={isWaiting}
              variant="outlined"
            >
              <FormattedMessage id="mandate.signature.delete-signature" />
            </Button>
          </Grid>
        )}
        <Grid item>
          <Button
            color="primary"
            disabled={!value || isWaiting}
            onClick={onConfirm}
            variant="contained"
          >
            <FormattedMessage id="mandate.signature.confirm" />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SignatureStep;
