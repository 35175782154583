import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { FormControl, MenuItem, TextField } from '@mui/material';
import useFetch from 'src/react-hydra-query/useFetch';
import { change } from '../../reducers/locale';
import { LANGUAGES_LIST } from '../../endpoints/intlApi';

const LanguageSelector = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const locale = useSelector((state) => state.locale.current);
  const { data } = useFetch({ url: LANGUAGES_LIST });
  const languages = data?.['hydra:member'] ?? [];
  const options = languages.map((lang) => ({ id: lang.locale, label: lang.name }));

  return (
    <div style={{ position: 'absolute', top: 10, right: 10, minWidth: 250 }}>
      <FormControl fullWidth>
        <TextField
          select
          label={intl.formatMessage({ id: 'startup.language-selector' })}
          value={locale}
          onChange={(event) => dispatch(change(event.target.value))}
        >
          {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    </div>
  );
};

export default LanguageSelector;
