import fr from './fr.json';
import es from './es.json';
import en from './en.json';
import ca from './ca.json';
import nl from './nl.json';

const messages = {
  fr,
  es,
  ca,
  nl,
  en,
  'en-GB': en,
};

export default messages;
