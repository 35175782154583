import { schema } from 'normalizr';

export const LANGUAGES_LIST = '/:clientToken/languages';

// ------------------------------------
// Schemas
// ------------------------------------

const LANGUAGE_SCHEMA = new schema.Entity('languages', {}, { idAttribute: '@id' });

const languageEntityType = {
  Language: LANGUAGE_SCHEMA,
};

export default languageEntityType;
