import { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Sketchfield from '@stadline/mui-sketch-field';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import useFetch from 'src/react-hydra-query/useFetch';
import MandateLayout from './MandateLayout';
import { getDeviceId } from '../../../selectors/config';
import useSocketConnection from '../../../socket.io-react/useSocketConnection';
import MandateInfo from './MandateInfo';

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const MandateScreen = ({ mandateId }) => {
  const theme = useTheme();
  const intl = useIntl();
  const deviceId = useSelector(getDeviceId);

  const { data: mandate } = useFetch({ url: mandateId });
  // EU bank accounts have an IBAN property that UK accounts don't have so we'll use this as a discriminator
  const isEUMandate = Boolean(mandate?.iban);

  const handleMessage = (_, message) => {
    switch (message.type) {
      case 'mandate_signature_reset':
        setValue(null);
        setIsWaiting(false);
        break;
      default:
      // do nothing
    }
  };

  const broadcast = useSocketConnection(`/:clientToken/devices/${deviceId}`, handleMessage);

  const [isWaiting, setIsWaiting] = useState(false);
  const [value, setValue] = useState(null);

  const onSketchClear = () => {
    setValue(null);
  };

  const onChange = (val) => {
    setValue(val);
  };

  const onConfirm = async () => {
    setIsWaiting(true);
    broadcast('message', {
      type: 'mandate_signed',
      payload: value,
    });
    await delay(3000);
    setIsWaiting(false);
  };

  return (
    <MandateLayout>
      <Grid container spacing={1} direction="column" style={{ height: '100%' }} wrap="nowrap">
        {isEUMandate && <MandateInfo mandate={mandate} />}
        <Grid item style={{ paddingTop: theme.spacing(3) }}>
          <Typography variant="h6">
            <FormattedMessage id="mandate.signature.title" />
          </Typography>
        </Grid>
        <Grid
          item
          style={{
            flex: 1,
            paddingTop: theme.spacing(2),
            maxHeight: isEUMandate ? '60vh' : 'auto',
          }}
        >
          <Sketchfield
            value={value}
            placeholder={intl.formatMessage({ id: 'mandate.signature.sketchfield.placeholder' })}
            width="100%"
            height="90%"
            onChange={onChange}
          />
          <Box width="100%" py={1} display="flex" justifyContent="flex-end">
            <Button color="primary" onClick={onSketchClear} variant="text">
              <FormattedMessage id="mandate.signature.delete-signature" />
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            borderTop: `1px solid ${theme.palette.grey[200]}`,
          }}
        >
          <Button
            color="primary"
            disabled={!value || isWaiting}
            onClick={onConfirm}
            variant="contained"
          >
            <FormattedMessage id="mandate.signature.confirm" />
          </Button>
        </Grid>
      </Grid>
    </MandateLayout>
  );
};

MandateScreen.propTypes = {
  mandateId: PropTypes.string.isRequired,
};

export default MandateScreen;
