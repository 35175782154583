import React from 'react';
import { compose } from 'recompose';
import { Route, Switch, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { withPayline } from 'react-payline';
import { getClientToken } from '../../selectors/config';
import Startup from '../../pages/Startup';
import ClientScreen from '../../pages/ClientScreen';
import NoMatch from '../../pages/NoMatch';

const App = () => {
  const clientToken = useSelector(getClientToken);
  return clientToken ? (
    <Switch>
      <Route exact path="/" component={Startup} />
      <Route path="/ready" component={ClientScreen} />
      <Route component={NoMatch} />
    </Switch>
  ) : (
    <NoMatch />
  );
};

const PAYLINE_ENV = process.env.REACT_APP_PAYLINE_ENVIRONMENT || 'HOMO';

export default compose(withRouter, withPayline(PAYLINE_ENV === 'PROD'))(App);
