import { AxiosError, AxiosInstance } from 'axios';
import { useCallback, useEffect } from 'react';
import useApiClient from 'src/services/useApiClient';
import type { MutationStatus } from '@tanstack/react-query';

interface Options {
  onStatusChange?: (status: MutationStatus) => void;
  onError?: (error: AxiosError, ...args: any[]) => void;
}

const noop = () => {};

function useMutation<A extends any[], R = unknown>(
  asyncMutator: (client: AxiosInstance, ...args: A) => Promise<R>,
  deps: ReadonlyArray<any>,
  options: Options = {}
) {
  const setStatus = options.onStatusChange ?? noop;

  useEffect(() => {
    setStatus('idle');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const client = useApiClient();

  return useCallback(async (...args: A) => {
    try {
      setStatus('loading');
      const result: R = await asyncMutator(client, ...args);

      setStatus('success');
      return result;
    } catch (error) {
      setStatus('error');
      if (options.onError) options.onError(error as AxiosError, ...args);
      throw error;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

type Requester = AxiosInstance['request'] & Omit<AxiosInstance, 'request'>;

function useRequestCallback<A extends any[], R = unknown>(
  asyncMutator: (request: Requester, ...args: A) => Promise<R>,
  deps: ReadonlyArray<any>,
  options?: Options
) {
  return useMutation(
    ({ request: axiosRequest, ...axiosAttributes }, ...args: A) => {
      const request: AxiosInstance['request'] = (...p) => axiosRequest(...p);
      Object.entries(axiosAttributes).forEach(([k, v]) => {
        request[k] = v;
      });

      return asyncMutator(request as Requester, ...args);
    },
     
    deps,
    options
  );
}

export default useRequestCallback;
