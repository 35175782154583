import React from 'react';
import { useIntl } from 'react-intl';
import { Stepper, Step, StepButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveStep, getSteps } from 'src/selectors/saleContract';
import { setStep } from 'src/reducers/saleContract';

const SaleContractNav = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const activeStep = useSelector(getActiveStep);
  const steps = useSelector(getSteps);

  return (
    <Stepper style={{ width: '90%' }} activeStep={activeStep}>
      {steps.map((step, index) => (
        <Step key={step}>
          <StepButton color="primary" onClick={() => dispatch(setStep(index))}>
            {intl.formatMessage({ id: step })}
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );
};

export default SaleContractNav;
