import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Typography,
  FormGroup,
  CircularProgress,
  Grid,
  Checkbox,
  FormControlLabel,
  useTheme,
  Box,
  Button,
} from '@mui/material';
import { PaylineWidget } from 'react-payline';
import { useDispatch, useSelector } from 'react-redux';
import useFetch from 'src/react-hydra-query/useFetch';
import useDerivedState from 'src/react-form/useDerivedState';
import useRequestCallback from 'src/react-hydra-query/useRequestCallback';
import PaylineWalletLayout from './PaylineWalletLayout';
import { saveState } from '../../../reducers/payline';
import { StyledPaylineWidgetWrapper } from './Payline.style';

import { getDeviceId } from '../../../selectors/config';
import useSocketConnection from '../../../socket.io-react/useSocketConnection';

const PaylineWalletScreen = ({ token, clubName, walletId }) => {
  const dispatch = useDispatch();
  const deviceId = useSelector(getDeviceId);

  const theme = useTheme();
  const intl = useIntl();
  const broadcast = useSocketConnection(`/:clientToken/devices/${deviceId}`);

  const { data: walletData, status } = useFetch({ url: walletId });
  const [useInRejects, setUseInRejects] = useDerivedState(
    () => walletData?.useInRejects ?? false,
    [walletData?.useInRejects],
    useRequestCallback(
      async (request, nextUseInRejects) =>
        request.put(walletId, { useInRejects: nextUseInRejects }),
      [walletId]
    )
  );

  const [isWallet, setIsWallet] = useState(false);

  /** @type {import('react-payline/src/PaylineWidget').FinalStateHasBeenReachedHandler} */

  const onShowFinalState = useCallback(
    (data) => {
      dispatch(saveState(data.state));
      broadcast('message', {
        type: 'payline_wallet_final_state',
        payload: data.state,
      });
    },
    [broadcast, dispatch]
  );

  const handleOnDidShowState = useCallback((data) => {
    if (data.state === 'MANAGE_WEB_WALLET') setIsWallet(true);
  }, []);

  return (
    <PaylineWalletLayout>
      <Grid item xs={12}>
        <Typography variant="h5" align="center" style={{ marginTop: 0 }}>
          <FormattedMessage id="payline.wallet.title" />
        </Typography>
      </Grid>

      {!token && status !== 'success' ? (
        <Grid container alignItems="center" justifyContent="center" style={{ height: '100vh' }}>
          <Grid item>
            <CircularProgress size={60} />
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <StyledPaylineWidgetWrapper>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox color="primary" />}
                checked={useInRejects}
                onChange={(event) => setUseInRejects(event.target.checked)}
                label={intl.formatMessage(
                  {
                    id: 'payline.wallet.useInRejects',
                  },
                  {
                    clubName: clubName || '-',
                  }
                )}
              />
            </FormGroup>
            <PaylineWidget
              token={token}
              template="lightbox"
              embeddedRedirectionAllowed
              onFinalStateHasBeenReached={onShowFinalState}
              onDidShowState={handleOnDidShowState}
            />
          </StyledPaylineWidgetWrapper>
          {isWallet && (
            <Box
              sx={{
                position: 'fixed',
                bottom: theme.spacing(3),
                right: theme.spacing(3),
              }}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={() => onShowFinalState({ state: 'FINAL_STATE' })}
              >
                {intl.formatMessage({ id: 'payline.wallet.end' })}
              </Button>
            </Box>
          )}
        </Grid>
      )}
    </PaylineWalletLayout>
  );
};

PaylineWalletScreen.propTypes = {
  token: PropTypes.string.isRequired,
  walletId: PropTypes.string.isRequired,
  clubName: PropTypes.string.isRequired,
};

export default PaylineWalletScreen;
