import { combineReducers } from '@reduxjs/toolkit';
import clientScreen from './clientScreen';
import saleContract from './saleContract';
import config from './config';
import entities from './entities';
import locale from './locale';
import customTheme from './customTheme';
import payline from './payline';

export default combineReducers({
  clientScreen,
  config,
  customTheme,
  entities,
  locale,
  saleContract,
  payline,
});
