import React from 'react';
import { FormattedMessage } from 'react-intl';

const NoMatch = () => (
  <h1>
    <FormattedMessage id="global.not-found" />
  </h1>
);

export default NoMatch;
