import { createSelector } from '@reduxjs/toolkit';

/**
 * Returns config state
 *
 * @param {object} state
 * @returns {object}
 */
export const getConfig = (state) => state.config;

/**
 * Returns current deviceId
 *
 * @param {object} state
 * @returns {string}
 */
export const getDeviceId = createSelector([getConfig], (config) => config.deviceId);

/**
 * Returns Socket.IO config
 *
 * @param {object} state
 * @returns {object}
 */
export const getSocketConfig = createSelector([getConfig], (config) => config.socket);

/**
 * Returns Api config
 *
 * @param {object} state
 * @returns {object}
 */
export const getApiConfig = createSelector([getConfig], (config) => config.api);

/**
 * Returns router config
 *
 * @param {object} state
 * @returns {object}
 */
export const getRouterConfig = createSelector([getConfig], (config) => config.router);

/**
 * Returns root URL
 *
 * @param {object} state
 * @returns {string}
 */
export const getRouterBaseUrl = createSelector(
  [getRouterConfig],
  (routerConfig) => routerConfig.baseUrl
);

/**
 * Returns router basename
 *
 * @param {object} state
 * @returns {string}
 */
export const getRouterBasename = createSelector(
  [getRouterConfig],
  (routerConfig) => routerConfig.basename
);

/**
 * Returns clientToken
 *
 * @param {object} state
 * @returns {string}
 */
export const getClientToken = createSelector([getConfig], (config) => config.clientToken);

/**
 * Returns a path with clientToken injected into
 *
 * @param {object} state
 * @param {{uri: string? }} props
 * @returns {string}
 */
export const getResolvedUri = (state, { uri = '' }) =>
  uri.replace(':clientToken', getClientToken(state));

/**
 * Returns groot params
 *
 * @param {object} state
 * @returns {object}
 */
export const getGrootParams = createSelector([getConfig], (config) => config.groot);

/**
 * Returns groot config
 *
 * @param {object} state
 * @returns {object}
 */
export const getGrootConfig = createSelector([getGrootParams], (grootParams) => grootParams.config);

/**
 * Returns groot config custom translation
 *
 * @param {object} state
 * @returns {object}
 */
export const getGrootConfigTranslation = createSelector(
  [getGrootConfig],
  (grootConfig) => grootConfig.translation
);
