import { schema } from 'normalizr';

export const IDENTIFY_CUSTOMIZED_THEME = '/:clientToken/identify_customized_theme';

// ------------------------------------
// Schemas
// ------------------------------------

const CUSTOMIZED_THEME_SCHEMA = new schema.Entity('customizedTheme', {}, { idAttribute: '@id' });

const customizedThemeEntityType = {
  CustomizedTheme: CUSTOMIZED_THEME_SCHEMA,
};

export default customizedThemeEntityType;
