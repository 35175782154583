import get from 'lodash.get';

/**
 * Returns all available entities
 *
 * @param {Object} state
 */
export const getEntities = (state) => state.entities;

/**
 * Returns a specific normalized entity
 *
 * @param {object} state
 * @param {{ collection: string, entityId: string }} props
 * @returns {object}
 */
export const getEntity = (state, { collection, entityId }) =>
  get(state, ['entities', collection, entityId]);
