import type { AxiosInstance, AxiosRequestConfig } from 'axios';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { getHTTPRequestConfiguration } from 'src/reducers/_sendHTTPRequest';
import makeApiClient from './makeApiClient';

const useApiClient = (): AxiosInstance => {
  const dispatch = useDispatch();
  return useMemo(() => {
    const client = makeApiClient();
    client.interceptors.request.use((request) =>
      dispatch(getHTTPRequestConfiguration(request) as AxiosRequestConfig)
    );
    client.interceptors.request.use((requestConfig) => {
      if (requestConfig.method?.toLocaleUpperCase() === 'DELETE') {
        return client.request({ ...requestConfig, method: 'GET' }).then(() => requestConfig);
      }
      return requestConfig;
    });

    return client;
  }, [dispatch]);
};

export default useApiClient;
