import React from 'react';
import { Grid } from '@mui/material';
import LogoLarge from '../../../components/LogoLarge';

const StandbyScreen = () => (
  <Grid
    container
    justifyContent="center"
    alignItems="center"
    style={{ background: '#f7f6f6', height: '100vh', position: 'relative' }}
  >
    <LogoLarge dimension={300} />
  </Grid>
);

export default StandbyScreen;
