import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getActiveStep, getSteps } from 'src/selectors/saleContract';
import useFetch from 'src/react-hydra-query/useFetch';
import { ARTICLES_LIST } from 'src/endpoints/saleApi';
import SaleContractLayout from './SaleContractLayout';
import SignatureStep from './SignatureStep';
import CommunicationStep from './CommunicationStep';
import CGVStep from './CGVStep';

const isRecurrent = (article) => {
  if (!article) return false;
  const { occurrences = null, recurrences = null } = article.repaymentSchedule;
  const occurrencesSize = occurrences ? occurrences.length : 0;
  const recurrencesSize = recurrences ? recurrences.length : 0;

  return occurrencesSize + recurrencesSize !== 0;
};
const SaleContract = ({ saleId, contactId }) => {
  const activeStep = useSelector(getActiveStep);
  const steps = useSelector(getSteps);

  // begin to move directly on CGVstep instead of passing props
  const { data: sale } = useFetch({ url: saleId });
  const { data: club } = useFetch({ url: sale?.clubId }, { enabled: Boolean(sale?.clubId) });
  const { data: contact } = useFetch({ url: contactId });
  const { data: articlesList } = useFetch({
    url: ARTICLES_LIST,
    params: {
      sale: saleId,
    },
  });

  const needMandate = useMemo(
    () => (articlesList?.['hydra:member'] ?? []).some((article) => isRecurrent(article)),
    [articlesList]
  );
  // end to move directly on CGVstep instead of passing props

  return (
    <SaleContractLayout>
      {(() => {
        switch (steps[activeStep]) {
          case 'sale-contract.steps-validate-cgv':
            return (
              <CGVStep
                saleId={saleId}
                sale={sale}
                club={club}
                needMandate={needMandate}
                contact={contact}
              />
            );
          case 'sale-contract.steps-choose-communication':
            return <CommunicationStep />;
          case 'sale-contract.steps-sign-form':
            return <SignatureStep />;
          default:
            return null;
        }
      })()}
    </SaleContractLayout>
  );
};

SaleContract.propTypes = {
  saleId: PropTypes.string.isRequired,
  contactId: PropTypes.string.isRequired,
};

export default SaleContract;
